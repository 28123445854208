import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {Accordion, Card, Button, Navbar, Nav, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import ToolRing from './images/toolring.png';

const Jan = {name: 'January', number: 0}
const Feb = {name: 'February', number: 1}
const Mar = {name: 'March', number: 2}
const Apr = {name: 'April', number: 3}
const May = {name: 'May', number: 4}
const Jun = {name: 'June', number: 5}
const Jul = {name: 'July', number: 6}
const Aug = {name: 'August', number: 7}
const Sep = {name: 'September', number: 8}
const Oct = {name: 'October', number: 9}
const Nov = {name: 'November', number: 10}
const Dec = {name: 'December', number: 11}

// {id: , name: '', location: '', startTime: 0, endTime: 0, shadow: 0, price: 0, months: []},

const fish = [
  {id: 1, name: 'Bitterling', location: 'River', startTime: 0, endTime: 0, shadow: 1, price: 900, months: [Jan, Feb, Mar, Nov, Dec], captured: false},
  {id: 2, name: 'Pale Chub', location: 'River', startTime: 9, endTime: 15, shadow: 1, price: 200, months: [], captured: false},
  {id: 3, name: 'Crucian Carp', location: 'River', startTime: 0, endTime: 0, shadow: 2, price: 160, months: [], captured: false},
  {id: 4, name: 'Dace', location: 'River', startTime: 16, endTime: 9, shadow: 3, price: 240, months: [], captured: false},
  {id: 5, name: 'Carp', location: 'Pond', startTime: 0, endTime: 0, shadow: 4, price: 300, months: [], captured: false},
  {id: 6, name: 'Koi', location: 'Pond', startTime: 16, endTime: 9, shadow: 4, price: 4000, months: [], captured: false},
  {id: 7, name: 'Goldfish', location: 'Pond', startTime: 0, endTime: 0, shadow: 1, price: 1300, months: [], captured: false},
  {id: 8, name: 'Pop-eyed Goldfish', location: 'Pond', startTime: 9, endTime: 16, shadow: 1, price: 1300, months: [], captured: false},
  {id: 9, name: 'Ranchu Goldfish', location: 'Pond', startTime: 9, endTime: 16, shadow: 2, price: 4500, months: [], captured: false},
  {id: 10, name: 'Killifish', location: 'Pond', startTime: 0, endTime: 0, shadow: 1, price: 300, months: [Apr, May, Jun, Jul, Aug], captured: false},
  {id: 11, name: 'Crawfish', location: 'Pond', startTime: 0, endTime: 0, shadow: 2, price: 200, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 12, name: 'Soft-shelled Turtle', location: 'River', startTime: 16, endTime: 9, shadow: 4, price: 3750, months: [Aug, Sep], captured: false},
  {id: 13, name: 'Snapping Turtle', location: 'River', startTime: 21, endTime: 4, shadow: 4, price: 5000, months: [Apr, May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 14, name: 'Tadpole', location: 'Pond', startTime: 0, endTime: 0, shadow: 1, price: 100, months: [Mar, Apr, May, Jun, Jul], captured: false},
  {id: 15, name: 'Frog', location: 'Pond', startTime: 0, endTime: 0, shadow: 2, price: 120, months: [May, Jun, Jul, Aug], captured: false},
  {id: 16, name: 'Freshwater Goby', location: 'River', startTime: 16, endTime: 9, shadow: 2, price: 400, months: [], captured: false},
  {id: 17, name: 'Loach', location: 'River', startTime: 0, endTime: 0, shadow: 2, price: 400, months: [Mar, Apr, May], captured: false},
  {id: 18, name: 'Catfish', location: 'Pond', startTime: 16, endTime: 9, shadow: 4, price: 800, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 19, name: 'Giant Snakehead', location: 'Pond', startTime: 9, endTime: 16, shadow: 4, price: 5500, months: [Jun, Jul, Aug], captured: false},
  {id: 20, name: 'Bluegill', location: 'River', startTime: 9, endTime: 16, shadow: 2, price: 180, months: [], captured: false},
  {id: 21, name: 'Yellow Perch', location: 'River', startTime: 0, endTime: 0, shadow: 3, price: 300, months: [Jan, Feb, Mar, Oct, Nov, Dec], captured: false},
  {id: 22, name: 'Black Bass', location: 'River', startTime: 0, endTime: 0, shadow: 4, price: 400, months: [], captured: false},
  {id: 23, name: 'Tilapia', location: 'River', startTime: 0, endTime: 0, shadow: 3, price: 800, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 24, name: 'Pike', location: 'River', startTime: 0, endTime: 0, shadow: 5, price: 1800, months: [Sep, Oct, Nov, Dec], captured: false},
  {id: 25, name: 'Pond Smelt', location: 'River', startTime: 0, endTime: 0, shadow: 2, price: 320, months: [Jan, Feb, Dec], captured: false},
  {id: 26, name: 'Sweetfish', location: 'River', startTime: 0, endTime: 0, shadow: 3, price: 900, months: [Jul, Aug, Sep], captured: false},
  {id: 27, name: 'Cherry Salmon', location: 'River (Clifftop)', startTime: 16, endTime: 9, shadow: 3, price: 1000, months: [Mar, Apr, May, Jun, Sep, Oct, Nov], captured: false},
  {id: 28, name: 'Char', location: 'River (Clifftop)', startTime: 16, endTime: 9, shadow: 3, price: 3800, months: [Mar, Apr, May, Jun, Sep, Oct, Nov], captured: false},
  {id: 29, name: 'Golden Trout', location: 'River (Clifftop)', startTime: 16, endTime: 9, shadow: 3, price: 15000, months: [Mar, Apr, Jun, Sep, Oct, Nov], captured: false},
  {id: 30, name: 'Stringfish', location: 'River (Clifftop)', startTime: 16, endTime: 9, shadow: 5, price: 15000, months: [Jan, Feb, Mar, Dec], captured: false},
  {id: 31, name: 'Salmon', location: 'River (Mouth)', startTime: 0, endTime: 0, shadow: 4, price: 700, months: [Sep], captured: false},
  {id: 32, name: 'King Salmon', location: 'River (Mouth)', startTime: 0, endTime: 0, shadow: 5, price: 1800, months: [Sep], captured: false},
  {id: 33, name: 'Mitten Crab', location: 'River', startTime: 16, endTime: 9, shadow: 2, price: 2000, months: [Sep, Oct, Nov], captured: false},
  {id: 34, name: 'Guppy', location: 'River', startTime: 9, endTime: 16, shadow: 1, price: 2000, months: [Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 35, name: 'Nibble Fish', location: 'River', startTime: 9, endTime: 16, shadow: 1, price: 1500, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 36, name: 'Angelfish', location: 'River', startTime: 16, endTime: 9, shadow: 2, price: 3000, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 37, name: 'Betta', location: 'River', startTime: 9, endTime: 16, shadow: 2, price: 2500, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 38, name: 'Neon Tetra', location: 'River', startTime: 9, endTime: 16, shadow: 1, price: 500, months: [Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 39, name: 'Rainbowfish', location: 'River', startTime: 9, endTime: 16, shadow: 1, price: 800, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 40, name: 'Piranha', location: 'River', startTime: [9, 21], endTime: [16, 4], shadow: 2, price: 2500, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 41, name: 'Arowana', location: 'River', startTime: 16, endTime: 9, shadow: 4, price: 10000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 42, name: 'Dorado', location: 'River', startTime: 4, endTime: 21, shadow: 5, price: 15000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 43, name: 'Gar', location: 'Pond', startTime: 16, endTime: 9, shadow: 5, price: 6000, months: [Jul, Aug, Sep, Oct], captured: false},
  {id: 44, name: 'Arapaima', location: 'River', startTime: 16, endTime: 9, shadow: 6, price: 10000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 45, name: 'Saddled Bichir', location: 'River', startTime: 21, endTime: 4, shadow: 4, price: 4000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 46, name: 'Sturgeon', location: 'River (Mouth)', startTime: 0, endTime: 0, shadow: 6, price: 10000, months: [Jan, Feb, Mar, Sep, Oct, Nov, Dec], captured: false},
  {id: 47, name: 'Sea Butterfly', location: 'Sea', startTime: 0, endTime: 0, shadow: 1, price: 1000, months: [Jan, Feb, Mar, Dec], captured: false},
  {id: 48, name: 'Sea Horse', location: 'Sea', startTime: 0, endTime: 0, shadow: 1, price: 1100, months: [Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 49, name: 'Clown Fish', location: 'Sea', startTime: 0, endTime: 0, shadow: 1, price: 650, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 50, name: 'Surgeonfish', location: 'Sea', startTime: 0, endTime: 0, shadow: 2, price: 1000, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 51, name: 'Butterfly Fish', location: 'Sea', startTime: 0, endTime: 0, shadow: 2, price: 1000, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 52, name: 'Napoleonfish', location: 'Sea', startTime: 4, endTime: 21, shadow: 6, price: 10000, months: [Jul, Aug], captured: false},
  {id: 53, name: 'Zebra Turkeyfish', location: 'Sea', startTime: 0, endTime: 0, shadow: 3, price: 500, months: [Apr, May, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 54, name: 'Blowfish', location: 'Sea', startTime: 18, endTime: 4, shadow: 3, price: 5000, months: [Jan, Feb, Nov, Dec], captured: false},
  {id: 55, name: 'Puffer Fish', location: 'Sea', startTime: 0, endTime: 0, shadow: 3, price: 250, months: [Jul, Aug, Sep], captured: false},
  {id: 56, name: 'Anchovy', location: 'Sea', startTime: 4, endTime: 21, shadow: 2, price: 200, months: [], captured: false},
  {id: 57, name: 'Horse Mackerel', location: 'Sea', startTime: 0, endTime: 0, shadow: 2, price: 150, months: [], captured: false},
  {id: 58, name: 'Barred Knifejaw', location: 'Sea', startTime: 0, endTime: 0, shadow: 3, price: 5000, months: [Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 59, name: 'Sea Bass', location: 'Sea', startTime: 0, endTime: 0, shadow: 5, price: 400, months: [], captured: false},
  {id: 60, name: 'Red Snapper', location: 'Sea', startTime: 0, endTime: 0, shadow: 4, price: 3000, months: [], captured: false},
  {id: 61, name: 'Dab', location: 'Sea', startTime: 0, endTime: 0, shadow: 3, price: 300, months: [Jan, Feb, Mar, Apr, Oct, Nov, Dec], captured: false},
  {id: 62, name: 'Olive Flounder', location: 'Sea', startTime: 0, endTime: 0, shadow: 5, price: 800, months: [], captured: false},
  {id: 63, name: 'Squid', location: 'Sea', startTime: 0, endTime: 0, shadow: 3, price: 500, months: [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Dec], captured: false},
  {id: 64, name: 'Moray Eel', location: 'Sea', startTime: 0, endTime: 0, shadow: 'Skinny', price: 2000, months: [Aug, Sep, Oct], captured: false},
  {id: 65, name: 'Ribbon Eel', location: 'Sea', startTime: 0, endTime: 0, shadow: 'Skinny', price: 600, months: [Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 66, name: 'Tuna', location: 'Pier', startTime: 0, endTime: 0, shadow: 6, price: 7000, months: [Jan, Feb, Mar, Apr, Nov, Dec], captured: false},
  {id: 67, name: 'Blue Marlin', location: 'Pier', startTime: 0, endTime: 0, shadow: 6, price: 10000, months: [Jan, Feb, Mar, Apr, Nov, Dec], captured: false},
  {id: 68, name: 'Giant Trevally', location: 'Pier', startTime: 0, endTime: 0, shadow: 5, price: 4500, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 69, name: 'Mahi-Mahi', location: 'Pier', startTime: 0, endTime: 0, shadow: 5, price: 6000, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 70, name: 'Ocean Sunfish', location: 'Sea', startTime: 4, endTime: 21, shadow: 'Fin', price: 4000, months: [Jul, Aug, Sep], captured: false},
  {id: 71, name: 'Ray', location: 'Sea', startTime: 4, endTime: 21, shadow: 5, price: 3000, months: [Aug, Sep, Oct, Nov], captured: false},
  {id: 72, name: 'Saw Shark', location: 'Sea', startTime: 16, endTime: 9, shadow: 'Fin', price: 12000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 73, name: 'Hammerhead Shark', location: 'Sea', startTime: 16, endTime: 9, shadow: 'Fin', price: 8000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 74, name: 'Great White Shark', location: 'Sea', startTime: 16, endTime: 9, shadow: 'Fin', price: 15000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 75, name: 'Whale Shark', location: 'Sea', startTime: 16, endTime: 9, shadow: 'Fin', price: 13000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 76, name: 'Suckerfish', location: 'Sea', startTime: 0, endTime: 0, shadow: 'Fin', price: 1500, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 77, name: 'Football Fish', location: 'Sea', startTime: 16, endTime: 9, shadow: 4, price: 2500, months: [Jan, Feb, Mar, Nov, Dec], captured: false},
  {id: 78, name: 'Oarfish', location: 'Sea', startTime: 0, endTime: 0, shadow: 6, price: 9000, months: [Jan, Feb, Mar, Apr, May, Dec], captured: false},
  {id: 79, name: 'Barreleye Fish', location: 'Sea', startTime: 21, endTime: 4, shadow: 2, price: 15000, months: [], captured: false},
  {id: 80, name: 'Coelacanth', location: 'Sea (Rain)', startTime: 0, endTime: 0, shadow: 6, price: 15000, months: [], captured: false},
];

//{id: , name: , location: , startTime: , endTime: , price: , months: [], captured: false},
const bugs = [
  {id: 1, name: 'Common Butterfly', location: 'Flying', startTime: 4, endTime: 19, price: 160, months: [Jan, Feb, Mar, Apr, May, Jun, Sep, Oct, Nov, Dec], captured: false},
  {id: 2, name: 'Yellow Butterfly', location: 'Flying', startTime: 4, endTime: 19, price: 160, months: [Mar, Apr, May, Jun, Sep, Oct], captured: false},
  {id: 3, name: 'Tiger Butterfly', location: 'Flying', startTime: 4, endTime: 19, price: 240, months: [Mar, Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 4, name: 'Peacock Butterfly', location: 'Flying', startTime: 4, endTime: 19, price: 2500, months: [Mar, Apr, May, Jun], captured: false},
  {id: 5, name: 'Common Bluebottle', location: 'Flying', startTime: 4, endTime: 19, price: 300, months: [Apr, May, Jun, Jul, Aug], captured: false},
  {id: 6, name: 'Paper Kite Butterfly', location: 'Flying', startTime: 8, endTime: 19, price: 1000, months: [], captured: false},
  {id: 7, name: 'Great Purple Emperor', location: 'Flying', startTime: 4, endTime: 19, price: 3000, months: [May, Jun, Jul, Aug], captured: false},
  {id: 8, name: 'Monarch Butterfly', location: 'Flying', startTime: 4, endTime: 17, price: 140, months: [Sep, Oct, Nov], captured: false},
  {id: 9, name: 'Emperor Butterfly', location: 'Flying', startTime: 17, endTime: 8, price: 4000, months: [Jan, Feb, Mar, Jun, Jul, Aug, Sep, Dec], captured: false},
  {id: 10, name: 'Agrias Butterly', location: 'Flying', startTime: 8, endTime: 17, price: 3000, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 11, name: "Rajah Brooke's Birdwing", location: 'Flying', startTime: 8, endTime: 17, price: 2500, months: [Jan, Feb, Apr, May, Jun, Jul, Aug, Sep, Dec], captured: false},
  {id: 12, name: "Queen Alexandra's Birdwing", location: 'Flying', startTime: 8, endTime: 16, price: 4000, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 13, name: 'Moth', location: 'Flying', startTime: 19, endTime: 4, price: 130, months: [], captured: false},
  {id: 14, name: 'Atlas Moth', location: 'On Trees', startTime: 19, endTime: 4, price: 3000, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 15, name: 'Madagascan Sunset Moth', location: 'Flying', startTime: 8, endTime: 4, price: 2500, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 16, name: 'Long Locust', location: 'Ground', startTime: 8, endTime: 16, price: 200, months: [Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 17, name: 'Migratory Locust', location: 'Ground', startTime: 8, endTime: 19, price: 600, months: [Aug, Sep, Oct, Nov], captured: false},
  {id: 18, name: 'Rice Grasshopper', location: 'Ground', startTime: 8, endTime: 19, price: 160, months: [Aug, Sep, Oct, Nov], captured: false},
  {id: 19, name: 'Grasshopper', location: 'Ground', startTime: 8, endTime: 17, price: 160, months: [Jul, Aug, Sep], captured: false},
  {id: 20, name: 'Cricket', location: 'Ground', startTime: 17, endTime: 8, price: 130, months: [Sep, Oct, Nov], captured: false},
  {id: 21, name: 'Bell Cricket', location: 'Ground', startTime: 17, endTime: 8, price: 430, months: [Sep, Oct], captured: false},
  {id: 22, name: 'Mantis', location: 'Flowers', startTime: 8, endTime: 17, price: 430, months: [Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 23, name: 'Orchid Mantis', location: 'Flowers', startTime: 8, endTime: 17, price: 2400, months: [Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 24, name: 'Honeybee', location: 'Flying', startTime: 8, endTime: 17, price: 200, months: [Mar, Apr, May, Jun, Jul], captured: false},
  {id: 25, name: 'Wasp', location: 'Shaking Trees', startTime: 0, endTime: 0, price: 2500, months: [], captured: false},
  {id: 26, name: 'Brown Cicada', location: 'On Trees', startTime: 8, endTime: 17, price: 250, months: [Jul, Aug], captured: false},
  {id: 27, name: 'Robust Cicada', location: 'On Trees', startTime: 8, endTime: 17, price: 300, months: [Jul, Aug], captured: false},
  {id: 28, name: 'Giant Cicada', location: 'On Trees', startTime: 8, endTime: 17, price: 500, months: [Jul, Aug], captured: false},
  {id: 29, name: 'Walker Cicada', location: 'On Trees', startTime: 8, endTime: 17, price: 400, months: [Aug, Sep], captured: false},
  {id: 30, name: 'Evening Cicada', location: 'On Trees', startTime: 4, endTime: 8, secondStartTime: 16, secondEndTime: 19, price: 550, months: [Jul, Aug], captured: false},
  {id: 31, name: 'Cicada Shell', location: 'On Trees', startTime: 0, endTime: 0, price: 10, months: [Jul, Aug], captured: false},
  {id: 32, name: 'Red Dragonfly', location: 'Flying', startTime: 8, endTime: 19, price: 180, months: [Sep, Oct], captured: false},
  {id: 33, name: 'Darner Dragonfly', location: 'Flying', startTime: 8, endTime: 17, price: 230, months: [Apr, May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 34, name: 'Banded Dragonfly', location: 'Flying', startTime: 8, endTime: 17, price: 4500, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 35, name: 'Damselfly', location: 'Flying', startTime: 0, endTime: 0, price: 500, months: [Jan, Feb, Nov, Dec], captured: false},
  {id: 36, name: 'Firefly', location: 'Flying', startTime: 19, endTime: 4, price: 300, months: [Jun], captured: false},
  {id: 37, name: 'Mole Cricket', location: 'Underground', startTime: 0, endTime: 0, price: 500, months: [Jan, Feb, Mar, Apr, May, Nov, Dec], captured: false},
  {id: 38, name: 'Pondskater', location: 'Freshwater', startTime: 8, endTime: 19, price: 130, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 39, name: 'Diving Beetle', location: 'Freshwater', startTime: 8, endTime: 19, price: 800, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 40, name: 'Giant Water Bug', location: 'Freshwater', startTime: 19, endTime: 8, price: 2000, months: [Apr, May, Jun, Jul, Aug, Sep], captured: false},
  {id: 41, name: 'Stinkbug', location: 'Flowers', startTime: 0, endTime: 0, price: 120, months: [Mar, Apr, May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 42, name: 'Man-faced Stinkbug', location: 'Flowers', startTime: 19, endTime: 8, price: 1000, months: [Mar, Apr, May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 43, name: 'Ladybug', location: 'Flowers', startTime: 8, endTime: 17, price: 200, months: [Mar, Apr, May, Jun, Sep], captured: false},
  {id: 44, name: 'Tiger Beetle', location: 'Ground', startTime: 0, endTime: 0, price: 1500, months: [Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 45, name: 'Jewel Beetle', location: 'Tree Stump', startTime: 0, endTime: 0, price: 2400, months: [Apr, May, Jun, Jul, Aug], captured: false},
  {id: 46, name: 'Violin Beetle', location: 'Tree Stump', startTime: 0, endTime: 0, price: 450, months: [May, Jun, Sep, Oct, Nov], captured: false},
  {id: 47, name: 'Citrus Long-Horned Beetle', location: 'Tree Stump', startTime: 0, endTime: 0, price: 350, months: [], captured: false},  
  {id: 48, name: 'Rosalia Batesi Beetle', location: 'Tree Stump', startTime: 0, endTime: 0, price: 3000, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 49, name: 'Blue Weevil Beetle', location: 'On Trees (Coconut)', startTime: 0, endTime: 0, price: 800, months: [Jul, Aug], captured: false},
  {id: 50, name: 'Dung Beetle', location: 'Ground', startTime: 0, endTime: 0, price: 3000, months: [Jan, Feb, Dec], captured: false},
  {id: 51, name: 'Earth-boring Dung Beetle', location: 'Ground', startTime: 0, endTime: 0, price: 300, months: [Jul, Aug, Sep], captured: false},
  {id: 52, name: 'Scarab Beetle', location: 'On Trees', startTime: 23, endTime: 8, price: 10000, months: [Jul, Aug], captured: false},
  {id: 53, name: 'Drone Beetle', location: 'On Trees', startTime: 0, endTime: 0, price: 200, months: [Jun, Jul, Aug], captured: false},
  {id: 54, name: 'Goliath Beetle', location: 'On Trees (Coconut)', startTime: 17, endTime: 8, price: 8000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 55, name: 'Saw Stag', location: 'On Trees', startTime: 0, endTime: 0, price: 2000, months: [Jul, Aug], captured: false},
  {id: 56, name: 'Miyama Stag', location: 'On Trees', startTime: 0, endTime: 0, price: 1000, months: [Jul, Aug], captured: false},
  {id: 57, name: 'Giant Stag', location: 'On Trees', startTime: 23, endTime: 8, price: 10000, months: [Jul, Aug], captured: false},
  {id: 58, name: 'Rainbow Stag', location: 'On Trees', startTime: 19, endTime: 8, price: 6000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 59, name: 'Cyclommatus Stag', location: 'On Trees (Coconut)', startTime: 17, endTime: 8, price: 8000, months: [Jul, Aug], captured: false},
  {id: 60, name: 'Golden Stag', location: 'On Trees (Coconut)', startTime: 17, endTime: 8, price: 12000, months: [Jul, Aug], captured: false},
  {id: 61, name: 'Giraffe Stag', location: 'On Trees (Coconut)', startTime: 17, endTime: 8, price: 12000, months: [Jul, Aug], captured: false},
  {id: 62, name: 'Horned Dynastid', location: 'On Trees', startTime: 17, endTime: 8, price: 1350, months: [Jul, Aug], captured: false},
  {id: 63, name: 'Horned Atlas', location: 'On Trees (Coconut)', startTime: 17, endTime: 8, price: 8000, months: [Jul, Aug], captured: false},
  {id: 64, name: 'Horned Elephant', location: 'On Trees (Coconut)', startTime: 17, endTime: 8, price: 8000, months: [Jul, Aug], captured: false},
  {id: 65, name: 'Horned Hercules', location: 'On Trees (Coconut)', startTime: 17, endTime: 8, price: 12000, months: [Jul, Aug], captured: false},
  {id: 66, name: 'Walking Stick', location: 'On Trees', startTime: 4, endTime: 8, secondStartTime: 17, secondEndTime: 19, price: 600, months: [Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 67, name: 'Walking Leaf', location: 'On Trees', startTime: 0, endTime: 0, price: 600, months: [Jul, Aug, Sep], captured: false},
  {id: 68, name: 'Bagworm', location: 'Shaking Trees', startTime: 0, endTime: 0, price: 600, months: [], captured: false},
  {id: 69, name: 'Ant', location: 'Rotten Food', startTime: 0, endTime: 0, price: 80, months: [], captured: false},
  {id: 70, name: 'Hermit Crab', location: 'Beach', startTime: 19, endTime: 8, price: 1000, months: [], captured: false},
  {id: 71, name: 'Wharf Roach', location: 'Beach Rocks', startTime: 0, endTime: 0, price: 200, months: [], captured: false},
  {id: 72, name: 'Fly', location: 'Trash', startTime: 0, endTime: 0, price: 60, months: [], captured: false},
  {id: 73, name: 'Mosquito', location: 'Flying', startTime: 17, endTime: 4, price: 130, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 74, name: 'Flea', location: 'Villagers', startTime: 0, endTime: 0, price: 70, months: [Apr, May, Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 75, name: 'Snail', location: 'On Rocks (Rain)', startTime: 0, endTime: 0, price: 250, months: [], captured: false},
  {id: 76, name: 'Pill Bug', location: 'Hitting Rocks', startTime: 11, endTime: 16, price: 250, months: [Jan, Feb, Mar, Apr, May, Jun, Sep, Oct, Nov, Dec], captured: false},
  {id: 77, name: 'Centipede', location: 'Hitting Rocks', startTime: 16, endTime: 11, price: 250, months: [Jan, Feb, Mar, Apr, May, Jun, Sep, Oct, Nov, Dec], captured: false},
  {id: 78, name: 'Spider', location: 'Shaking Trees', startTime: 19, endTime: 8, price: 600, months: [], captured: false},
  {id: 79, name: 'Tarantula', location: 'Ground', startTime: 19, endTime: 4, price: 8000, months: [Jan, Feb, Mar, Apr, Nov, Dec], captured: false},
  {id: 80, name: 'Scorpion', location: 'Ground', startTime: 19, endTime: 4, price: 8000, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
];

const deep = [
  {id: 1, name: 'Seaweed', location: 'Many Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 600, months: [Oct, Nov, Dec, Jan, Feb, Mar, Apr, May, Jun, Jul], captured: false},
  {id: 2, name: 'Sea Grapes', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 900, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 3, name: 'Sea Cucumber', location: 'Normal Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 500, months: [Nov, Dec, Jan, Feb, Mar, Apr], captured: false},
  {id: 4, name: 'Sea Pig', location: 'Few Bubbles', startTime: 16, endTime: 9, shadow: "Large", price: 10000, months: [Nov, Dec, Jan, Feb], captured: false},
  {id: 5, name: 'Sea Star', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 500, months: [], captured: false},
  {id: 6, name: 'Sea Urchin', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 1700, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 7, name: 'Slate Pencil Urchin', location: 'Normal Bubbles', startTime: 16, endTime: 9, shadow: "Large", price: 2000, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 8, name: 'Sea Anemone', location: 'Many Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 500, months: [], captured: false},
  {id: 9, name: 'Moon Jellyfish', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 600, months: [Jul, Aug, Sep], captured: false},
  {id: 10, name: 'Sea Slug', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Small", price: 600, months: [], captured: false},
  {id: 11, name: 'Peal Oyster', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 2800, months: [], captured: false}, 
  {id: 12, name: 'Mussel', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 1500, months: [Jun, Jul, Aug, Sep, Oct, Nov, Dec], captured: false},
  {id: 13, name: 'Oyster', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 1100, months: [Sep, Oct, Nov, Dec, Jan, Feb], captured: false},
  {id: 14, name: 'Scallop', location: 'No Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 1200, months: [], captured: false},
  {id: 15, name: 'Whelk', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 1000, months: [], captured: false},
  {id: 16, name: 'Turban Shell', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 1000, months: [Mar, Apr, May, Sep, Oct, Nov, Dec], captured: false},
  {id: 17, name: 'Abalone', location: 'Normal Bubbles', startTime: 16, endTime: 9, shadow: "Medium", price: 2000, months: [Jun, Jul, Aug, Sep, Oct, Nov, Dec, Jan], captured: false},
  {id: 18, name: 'Gigas Giant Clam', location: 'Many Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 15000, months: [May, Jun, Jul, Aug, Sep], captured: false},
  {id: 19, name: 'Chambered Nautilus', location: 'Normal Bubbles', startTime: 16, endTime: 9, shadow: "Large", price: 1800, months: [Mar, Apr, May, Jun], captured: false},
  {id: 20, name: 'Octopus', location: 'Normal Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 1200, months: [], captured: false},
  {id: 21, name: 'Umbrella Octopus', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 6000, months: [Mar, Apr, May, Sep, Oct, Nov], captured: false},
  {id: 22, name: 'Vampire Squid', location: 'Normal Bubbles', startTime: 16, endTime: 9, shadow: "Large", price: 10000, months: [May, Jun, Jul, Aug], captured: false},
  {id: 23, name: 'Firefly Squid', location: 'Few Bubbles', startTime: 21, endTime: 4, shadow: "Small", price: 1400, months: [Mar, Apr, May, Jun], captured: false},
  {id: 24, name: 'Gazami Crab', location: 'Normal Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 2200, months: [Jun, Jul, Aug, Sep, Oct, Nov], captured: false},
  {id: 25, name: 'Dungeness Crab', location: 'Normal Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 1900, months: [Nov, Dec, Jan, Feb, Mar, Apr, May], captured: false},
  {id: 26, name: 'Snow Crab', location: 'Normal Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 6000, months: [Nov, Dec, Jan, Feb, Mar, Apr], captured: false},
  {id: 27, name: 'Red King Crab', location: 'Normal Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 8000, months: [Nov, Dec, Jan, Feb, Mar], captured: false},
  {id: 28, name: 'Acorn Barnacle', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Small", price: 600, months: [], captured: false},
  {id: 29, name: 'Spider Crab', location: 'Many Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 10000, months: [Mar, Apr], captured: false},
  {id: 30, name: 'Tiger Prawn', location: 'Few Bubbles', startTime: 16, endTime: 9, shadow: "Medium", price: 3000, months: [Jun, Jul, Aug, Sep], captured: false},
  {id: 31, name: 'Sweet Shrimp', location: 'Few Bubbles', startTime: 16, endTime: 9, shadow: "Medium", price: 1400, months: [Sep, Oct, Nov, Dec, Jan, Feb], captured: false},
  {id: 32, name: 'Mantis Shrimp', location: 'Few Bubbles', startTime: 16, endTime: 9, shadow: "Medium", price: 2500, months: [], captured: false},
  {id: 33, name: 'Spiny Lobster', location: 'Many Bubbles', startTime: 21, endTime: 4, shadow: "Large", price: 3000, months: [Oct, Nov, Dec], captured: false},
  {id: 34, name: 'Lobster', location: 'Many Bubbles', startTime: 0, endTime: 0, shadow: "Large", price: 4500, months: [Apr, May, Jun, Dec, Jan], captured: false},
  {id: 35, name: 'Giant Isopod', location: 'Normal Bubbles', startTime: [9, 21], endTime: [16, 4], shadow: "Large", price: 9000, months: [Jul, Aug, Sep, Oct], captured: false},
  {id: 36, name: 'Horseshoe Crab', location: 'Normal Bubbles', startTime: 21, endTime: 4, shadow: "Large", price: 1500, months: [Apr, May, Jun, Jul, Aug], captured: false},
  {id: 37, name: 'Sea Pineapple', location: 'Few Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 1500, months: [Apr, May, Jun, Jul, Aug], captured: false},
  {id: 38, name: 'Spotted Garden Eel', location: 'Few Bubbles', startTime: 4, endTime: 21, shadow: "Medium", price: 1100, months: [May, Jun, Jul, Aug, Sep, Oct], captured: false},
  {id: 39, name: 'Flatworm', location: 'Few Bubbles', startTime: 16, endTime: 9, shadow: "Small", price: 200, months: [Aug, Sep], captured: false},
  {id: 40, name: 'Venus Flower Basket', location: 'Normal Bubbles', startTime: 0, endTime: 0, shadow: "Medium", price: 5000, months: [Oct, Nov, Dec, Jan, Feb], captured: false},
]; 

class Ac extends Component {

  state = {
    filterAllTime: false,
    filterAddAllDay: true,
    filterAddTimeSpecific: true,
    filterLocation: 'All',
    filterBellMin: 0,
    filterOnlyMonth: false,
    filterDate: '',
    filterTime: '',
    filterDropBoxPrice: false,
    toggleDataSet: 'Fish',
    fish,
    bugs,
    deep
  }

  componentDidMount() {
    const nameColumn = {id: 1,Header: 'Name', accessor: 'name',isActive: this.setColumnActiveOnMount(true, true)};
    const locationColumn = {id: 2,Header: 'Location', accessor: 'location', isActive: this.setColumnActiveOnMount(true, true)};
    const startTimeColumn = {id: 3,Header: 'Start Time', accessor: (row) => this.formatTime(row.startTime), isActive: this.setColumnActiveOnMount(true), maxWidth: 100};
    const endTimeColumn = {id: 4,Header: 'End Time', accessor: (row) => this.formatTime(row.endTime), isActive: this.setColumnActiveOnMount(true), maxWidth: 100};
    const shadowColumn = {id: 5,Header: 'Shadow', accessor: 'shadow', isActive: this.setColumnActiveOnMount(true, true), maxWidth: 100};
    const priceColumn = {id: 6,Header: 'Price', accessor: (row) => this.state.filterDropBoxPrice ? (row.price - (row.price * 0.2)) : row.price, isActive: this.setColumnActiveOnMount(true, true), maxWidth: 150};
    const monthsColumn = {id: 7,Header: 'Months', accessor: (row) => this.displayMonths(row), isActive: this.setColumnActiveOnMount(true)};
    const capturedColumn = {id: 8, Header: 'Captured', accessor: (row) => <button id={row.id} onClick={this.handleCapturedChange}>{this.displayCapturedButtonText(row)}</button>, isActive: this.setColumnActiveOnMount(false)};
    const cjColumn = {id: 9, Header: 'CJ Price', accessor: (row) => row.price * 1.5, mobileIsActive: true, isActive: this.setColumnActiveOnMount(false)}
    const flickColumn = {id: 10, Header: 'Flick Price', accessor: (row) => row.price * 1.5, mobileIsActive: true, isActive: this.setColumnActiveOnMount(false)}
    
    const allColumns = [nameColumn, locationColumn, startTimeColumn, endTimeColumn, shadowColumn, priceColumn, monthsColumn, capturedColumn];
    this.setState({allColumns, cjColumn, flickColumn});
  }

  formatTime = time => {
    if(time.length > 1){
      let tempString = '';
      time.forEach(tempTime => {
        if(tempString.length > 1){
          tempString += "|"
        }
        if(tempTime > 12){
          tempString += `${tempTime - 12}pm`
        } else {
          tempString += `${tempTime}am`
        }
      })
      return tempString
    } else {
      if(time === 0){
        return `All Day`
      } else if(time > 12) {
        return `${time - 12}pm`
      } else {
        return `${time}am`
      }
    }
  }

  setColumnActiveOnMount = (isActive, mobileIsActive = false) => {
    if(window.innerWidth < 1000) {
      if(!mobileIsActive){
        return false
      } else {
        return true
      }
    } else {
      return isActive
    }
  }

  displayMonths = row => {
    if(row.months.length > 0) {
      return row.months.map((month, i) => {
        if(i === row.months.length - 1) {
          return ` and ${month.name}`
        } else {
          return `${month.name}, `
        }
      })
    } else {
      return 'All Year'
    }
  }

  displayCapturedButtonText = row => {
    if(!row.captured) {
      return 'Capture'
    } else {
      return 'Release'
    }
  }

  checkMonth = (months, myTime) => {
    let returnValue = false;
    months.forEach(month => {
      if(month.number === myTime.getMonth()){
        returnValue = true;
      }
    })
    return returnValue;
  }

  checkLocation = myItem => {
    if(this.state.filterLocation === 'All') {
      return true;
    } else if((this.state.filterLocation === 'Sea') && (myItem.location === 'Sea' || myItem.location === 'Pier' || myItem.location === 'Sea (Rain)')) {
      return true;
    } else if((this.state.filterLocation === 'Pond') && myItem.location === 'Pond') {
      return true;
    } else if ((this.state.filterLocation === 'River') && (myItem.location ==='River' || myItem.location === 'River (Clifftop)' || myItem.location === 'River (Mouth)')) {
      return true;
    } else if (this.state.filterLocation === myItem.location){
      return true;
    }
  }

  returnData = () => {
    let myTime = null;
    if(this.state.filterDate === '') {
      myTime = new Date();
    } else {
      myTime = new Date(this.state.filterDate + ' ' + this.state.filterTime);
    }
    let returnData = [];
    let returnCapturedData = [];
    let dataSet;
    if(this.state.toggleDataSet === "Fish"){
       dataSet = this.state.fish;
    } else if(this.state.toggleDataSet === "SeaCreatures"){
      dataSet = this.state.deep;
    } else if(this.state.toggleDataSet === "Bugs"){
      dataSet = this.state.bugs;
    }
    dataSet.forEach(myItem => {
      if(!myItem.captured){
        if(myItem.price >= this.state.filterBellMin) {
          if(this.checkLocation(myItem)){
            if(this.state.filterAllTime){
              returnData.push(myItem)
            } else if(myItem.months.length === 0 || this.checkMonth(myItem.months, myTime)) {
              if(myItem.startTime === 0 && this.state.filterAddAllDay) {
                returnData.push(myItem)
              } else if(myItem.startTime !== 0 && this.state.filterAddTimeSpecific) {
                if(myTime.getHours() >= myItem.startTime && myTime.getHours() < myItem.endTime) {
                  returnData.push(myItem)
                } else if(myTime.getHours() >= myItem.startTime && myItem.endTime < myItem.startTime) {
                  returnData.push(myItem)
                } else if(this.state.filterOnlyMonth) {
                  returnData.push(myItem)
                }
              }
            }
          }
        }
      } else {
        returnCapturedData.push(myItem)
      }
    })
    return [...returnData, ...returnCapturedData]
  }

  returnColumns = () => {
    let returnList = [];
    if(this.state.allColumns) {
      this.state.allColumns.forEach(column => {
        if(column.isActive){
          if(this.state.toggleDataSet === 'Bugs'){
            if(column.Header !== 'Shadow'){
              returnList.push(column)
            }
          } else {
            returnList.push(column)
          }
        }
      })
    }
    if(this.state.cjColumn || this.state.flickColumn) {
      if(this.state.toggleDataSet === 'Fish' || this.state.toggleDataSet === "SeaCreatures"){
        if(this.state.cjColumn.isActive){
          returnList.push(this.state.cjColumn)
        }
      } else if(this.state.toggleDataSet === 'Bugs'){
        if(this.state.flickColumn.isActive){
          returnList.push(this.state.flickColumn)
        }
      }
    }
    return returnList;
  }

  handleAllDayChange = () => {
    this.setState({filterAddAllDay: !this.state.filterAddAllDay})
  }

  handleLocationChange = e => {
    this.setState({filterLocation: e.target.value})
  }

  handleBellMinChange = e => {
    this.setState({filterBellMin: e.target.value})
  }

  handleOnlyAllDayChange = () => {
    this.setState({filterAddTimeSpecific: !this.state.filterAddTimeSpecific})
  }

  handleDateChange = e => {
    this.setState({filterDate: e.target.value})
  }

  handleTimeChange = e => {
    this.setState({filterTime: e.target.value})
  }

  handleOnlyMonthChange = e => {
    this.setState({filterOnlyMonth: !this.state.filterOnlyMonth})
  }

  handleColumnChange = e => {
    let id = parseInt(e.target.id);
    this.setState(state => {
      const allColumns = state.allColumns.map(column => {
        if(column.id === id){
          return {id, Header: column.Header, accessor: column.accessor, isActive: !column.isActive}
        } else {
          return column
        }
      });
      return { allColumns };
    });
  }

  handleCapturedChange = e => {
    let id = parseInt(e.target.id);
    if(this.state.toggleDataSet === 'Fish'){
      this.setState(state => {
        const fish = state.fish.map(myFish => {
          if(myFish.id === id){
            return {...myFish, captured: !myFish.captured}
          } else {
            return myFish
          }
        });
        return { fish };
      });
    } else if(this.state.toggleDataSet === 'SeaCreatures'){
      console.log('test');
      this.setState(state => {
        const deep = state.deep.map(myFish => {
          if(myFish.id === id){
            return {...myFish, captured: !myFish.captured}
          } else {
            return myFish
          }
        });
        return { deep };
      });
    } else {
      this.setState(state => {
        const bugs = state.bugs.map(myBug => {
          if(myBug.id === id){
            return {...myBug, captured: !myBug.captured}
          } else {
            return myBug
          }
        });
        return { bugs };
      })
    }
  }

  handleDropBoxPriceChange = () => {
    this.setState({filterDropBoxPrice: !this.state.filterDropBoxPrice})
  }

  handleToggleFishBugs = value => {
    this.setState({toggleDataSet: value, filterLocation: 'All', cjColumn:{...this.state.cjColumn, isActive: false}, flickColumn:{...this.state.flickColumn, isActive: false}})
  }

  handleCJColumnChange = () => {
    this.setState({cjColumn:{...this.state.cjColumn, isActive: !this.state.cjColumn.isActive}})
  }

  handleFlickColumnChange = () => {
    this.setState({flickColumn:{...this.state.flickColumn, isActive: !this.state.flickColumn.isActive}})
  }

  handleAllTimeChange = () => {
    this.setState({filterAllTime: !this.state.filterAllTime});
  }

  resetCapturedFish = () => {
    this.setState({fish, bugs, deep})
  }

  displayLocationOptions = () => {
    if(this.state.toggleDataSet === 'Fish'){
      return(
        <>
          <option>All</option>
          <option>River</option>
          <option>Sea</option>
          <option>Pond</option>
        </>
      )
    } else if(this.state.toggleDataSet === 'SeaCreatures') {
      return(
        <>
          <option>All</option>
          <option>Normal Bubbles</option>
          <option>Few Bubbles</option>
          <option>Many Bubbles</option>
          <option>No Bubbles</option>
        </>
      )
    } else {
      return (
        <>
          <option>All</option>
          <option>Flying</option>
          <option>Tree Stump</option>
          <option>Ground</option>
          <option>Flowers</option>
          <option>On Trees</option>
          <option>On Tree (Coconut)</option>
          <option>Shaking Trees</option>
          <option>Underground</option>
          <option>Freshwater</option>
          <option>Rotten Food</option>
          <option>Beach Rocks</option>
          <option>Trash</option>
          <option>Villagers</option>
          <option>On Rocks</option>
          <option>Hitting Rocks</option>
          <option>Beach</option>
        </>
      )
    }
  }

  addZeroBeforeHoursOrMinutes = value => {
    if(value < 10){
      value = "0" + value;
    }
    return value
  }

  render() {
    return(
      <>
        <Navbar bg='dark' variant='dark'>
          <Navbar.Brand><img src={ToolRing} alt='Toolring Logo'/></Navbar.Brand>
          <Navbar.Brand>ToolRing</Navbar.Brand>
          <Nav>
            <Nav.Link href='https://forms.gle/pGHTmDExvcJLjz276' target='_blank'>Contact</Nav.Link>
            <Nav.Link href='https://paypal.me/toolring?locale.x=en_US' target='_blank'>PayPal</Nav.Link>
          </Nav>
        </Navbar>
        <Accordion defaultActiveKey='1'>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} eventKey='0'>Filters</Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey='0'>
              <Card.Body>
              <ToggleButtonGroup type="radio" name="options" defaultValue={1} value={this.state.toggleDataSet} onChange={this.handleToggleFishBugs}>
                <ToggleButton value='Fish'>Fish</ToggleButton>
                <ToggleButton value='SeaCreatures'>Sea Creatures</ToggleButton>
                <ToggleButton value='Bugs'>Bugs</ToggleButton>
              </ToggleButtonGroup>
              <ul>
                <li><label>Filter For All Time: <input type='checkbox' defaultChecked={this.state.filterAllTime} value={this.state.filterAllTime} onChange={this.handleAllTimeChange}/></label></li>
                {
                  !this.state.filterAllTime ?
                  <>
                    <li><label>Filter Add All Day {this.state.toggleDataSet}: <input type='checkbox' defaultChecked={this.state.filterAddAllDay} onChange={this.handleAllDayChange}/></label></li>
                    <li><label>Filter Add Time Specific {this.state.toggleDataSet}: <input type='checkbox' defaultChecked={this.state.filterAddTimeSpecific} onChange={this.handleOnlyAllDayChange}/></label></li>
                    <li><label>Pick Day: <input type='date' value={this.state.filterDate !== '' ? this.state.filterDate : new Date().toLocaleDateString('en-CA')} onChange={this.handleDateChange}/> {this.state.filterOnlyMonth ? null : <label>Pick Time: <input type='time' value={this.state.filterTime !== '' ? this.state.filterTime : `${this.addZeroBeforeHoursOrMinutes(new Date().getHours())}:${this.addZeroBeforeHoursOrMinutes(new Date().getMinutes())}`} onChange={this.handleTimeChange}/></label>}</label></li>
                    <li><label>See {this.state.toggleDataSet} for the Month: <input type='checkbox' defaultChecked={this.state.filterOnlyMonth} value={this.state.filterOnlyMonth} onChange={this.handleOnlyMonthChange}/></label></li>
                  </>
                  : null
                }
                <li><label>Filter Location: <select value={this.state.filterLocation} onChange={this.handleLocationChange}>{this.displayLocationOptions()}</select></label></li>
                <li><label>Bell Minimum: <input type='range' min={0} max={15000} value={this.state.filterBellMin} onChange={this.handleBellMinChange}/> {this.state.filterBellMin}</label></li>
                <li><label>Drop Box Prices: <input type='checkbox' defaultChecked={this.state.filterDropBoxPrice} value={this.state.filterDropBoxPrice} onChange={this.handleDropBoxPriceChange}/></label></li>
                {
                  this.state.allColumns ? 
                  this.state.allColumns[7].isActive? <li><button onClick={this.resetCapturedFish}>Reset Captured</button></li> : null
                  : <p>Loading...</p>
                }
                <li>Active Columns:</li>
                <ul>
                  {
                    this.state.allColumns ? 
                    this.state.allColumns.map(column =>
                      this.state.toggleDataSet === 'Fish' ? <li key={column.id}><label>{column.Header}: <input type='checkbox' id={column.id} defaultChecked={column.isActive} value={column.isActive} onChange={this.handleColumnChange}/></label></li> : column.Header !== 'Shadow' ? <li key={column.id}><label>{column.Header}: <input type='checkbox' id={column.id} defaultChecked={column.isActive} value={column.isActive} onChange={this.handleColumnChange}/></label></li> : null
                    ) : <p>Loading...</p>
                  }
                  {
                    this.state.cjColumn || this.state.flickColumn ? 
                    this.state.toggleDataSet === 'Fish' ? <li key={this.state.cjColumn.id}><label>{this.state.cjColumn.Header}: <input type='checkbox' id={this.state.cjColumn.id} defaultChecked={this.state.cjColumn.isActive} value={this.state.cjColumn.isActive} onChange={this.handleCJColumnChange}/></label></li> : this.state.toggleDataSet === 'SeaCreatures' ? null : <li key={this.state.flickColumn.id}><label>{this.state.flickColumn.Header}: <input type='checkbox' id={this.state.flickColumn.id} defaultChecked={this.state.flickColumn.isActive} value={this.state.flickColumn.isActive} onChange={this.handleFlickColumnChange}/></label></li> 
                    : <p>Loading...</p>
                  }
                </ul>
              </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <ReactTable columns={this.returnColumns()} data={this.returnData()} pageSize={this.returnData().length} showPagination={false}/>
      </>
    )
  }
}

export default Ac;